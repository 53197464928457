<template>
  <v-row v-if="isError" v-bind="{ justify: 'center' }">
    <v-dialog v-model="dialog" persistent max-width="590">
      <div class="modal-error">
        <v-card>
          <v-card-title class="headline">
            <!-- <svg
              class="h-6 w-6 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg> -->
          </v-card-title>

          <v-card-text>
            <v-row v-bind="{ justify: 'center' }">
              <v-col cols="12" class="text-center">
                <img class="icon" src="@/assets/icon/fail.svg" />
              </v-col>
              <v-col class="text-center">
                <span class="text-red text-2xl"
                  >เกิดข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง</span
                >
              </v-col></v-row
            >
          </v-card-text>

          <v-card-actions>
            <v-row v-bind="{ justify: 'center' }">
              <CustomButton
                color="gray"
                @click="reload()"
                title="ลองอีกครั้ง"
              />
            </v-row>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import CustomButton from './CustomButton'
export default {
  name: 'Error',
  data() {
    return {
      dialog: true,
    }
  },
  computed: {
    ...mapState({
      // isLoading: state => state.model.isLoading,
      isError: state => state.model.isError,
    }),
  },
  components: {
    CustomButton,
  },
  methods: {
    reload() {
      location.reload()
      //   window.location.href = window.location.href
    },
  },
}
</script>

<style lang="stylus">
.modal-error
    background-color white
    z-index 10
    overflow hidden

.btn-reload
    border-radius 4px
    padding 1rem
    background-color #D9D9D9
</style>
