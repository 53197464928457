import Thailand from '../config/thailand.json'
import ThailandUpdate from '../config/thailandAddress.json'

const state = () => ({
  data: [],
  warrantyPrice: 0,
  payload: null,
  isError: false,
  isLoading: false,
})

// getters
const getters = {
  getProvince: () => {
    let province = []
    Thailand.map(items => {
      const check = province.filter(
        i => items.province === i.province,
      )
      if (check?.length < 1) {
        province.push({
          province: items.province,
          province_code: items.province_code,
        })
      }
    })
    return province
  },
  getDistrict: () => province => {
    let data = []
    Thailand.filter(items => items.province === province).map(
      items => {
        const check = data.filter(i => items.amphoe === i.amphoe)
        if (check?.length < 1) {
          data.push({
            amphoe: items.amphoe,
            amphoe_code: items.amphoe_code,
          })
        }
      },
    )
    return data
  },
  getSubDistrict: () => (province, amphoe) => {
    let data = []
    Thailand.filter(items => items.province === province)
      .filter(items => items.amphoe === amphoe)
      .map(items => {
        const check = data.filter(i => items.amphoe === i.amphoe)
        if (check?.length < 1) {
          data.push({
            district: items.district,
            district_code: items.district_code,
          })
        }
      })
    return data
  },
  getZipcode: () => (amphoe, tambon) => {
    const data = Thailand.filter(
      items => items.amphoe === amphoe && items.district === tambon,
    )
    return data?.length > 0 ? `${data[0].zipcode}` : ''
  },
  getAll: () => {
    return Thailand || []
  },

  getThailandAddress: () => {
    // compare with Thailand and ThailandUpdate
    // if Thailand not match with ThailandUpdate then add is_not_match = true
    // ThailandUpdate json to object

    const updated = Thailand.map(items => {
      const check = ThailandUpdate.filter(
        i =>
          i.province === items.province &&
          i.amphoe === items.amphoe &&
          i.district === items.district &&
          i.zipcode === items.zipcode,
      )
      if (check?.length < 1) {
        return {
          ...items,
          is_not_match: true,
        }
      }
      return {
        ...items,
        match: check[0],
        is_not_match: false,
      }
    })

    return updated
  },

  getThailandUpdate: () => {
    return ThailandUpdate || []
  },

  //   getModelByPath: state => path => {
  //     return state.data.filter(items => items.path === path)[0] ?? null
  //   },
  //   getModelByName: state => name => {
  //     return state.data.filter(items => items.name === name)[0] ?? null
  //   },
  //   getModelByNameAndColor: state => (name, color) => {
  //     const modelColor = state.data
  //       .filter(items => items.name === name)[0]
  //       ?.model?.filter(items => items.color === color)
  //     return modelColor ? modelColor[0] : null
  //   },
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
