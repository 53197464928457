import Vue from 'vue'
import Vuex from 'vuex'
import model from '@/store/model'
import bundle from '@/store/bundle'
import formStore from '@/store/form'
import branchStore from '@/store/branch'
import thailandStore from '@/store/thailand'
import order from '@/store/order'
import cart from '@/store/cart'
import kbank from '@/store/kbank'
import campaign from '@/store/campaign'
import recaptcha from '@/store/recaptcha'
import preRegister from '@/store/preRegister'
import paymentType from '@/store/paymentType'
import remaining from '@/store/remaining'
import staticPage from '@/store/staticPage'
import stock from '@/store/stock'
import auth from '@/store/auth'
import user from '@/store/user'

import createLogger from './logger'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.VUE_APP_ENV === 'local'
const plugins = []

// console.log('debug', debug)
if (debug) {
  plugins.push(createLogger())
  plugins.push(createPersistedState())
}

export default new Vuex.Store({
  modules: {
    // stepStore,
    model,
    bundle,
    formStore,
    branchStore,
    thailandStore,
    // paymentTypeStore,
    order,
    cart,
    kbank,
    campaign,
    recaptcha,
    preRegister,
    paymentType,
    remaining,
    staticPage,
    stock,
    auth,
    user,
  },
  strict: debug,
  plugins: plugins,
})
