export const orderDetailTransform = params => {
  return {
    order: params.order ?? params.ref_id ?? null,
    code: params.code ?? null,
    barcode: params.barcode ?? null,
    branch_id: params.branch_id ?? null,
    branch_code: params.branch_code ?? null,
    deposit_number: params.deposit_number ?? null,

    itec_sale_order_status: params.itec_sale_order_status ?? null,

    receive_branch_name: params.receive_branch_name ?? '',
    receive_date: params.receive_date ?? '',
    receive_time: params.receive_time ?? '',
    receive_branch_address: params.receive_branch_address ?? null,

    full_name: params.full_name ?? '',
    phone: params.phone ?? '',
    email: params.email ?? '',
    address: params?.address?.address ?? '',
    sub_district: params.sub_district ?? '',
    district: params.district ?? '',
    province: params.province ?? '',
    postcode: params.postcode ?? '',

    tax_address: params?.bill_address
      ? {
          tax_id: params?.bill_address?.tax_id ?? '',
          is_tax_invoice:
            params?.bill_address?.is_tax_invoice ?? false,
          tax_invoice_type:
            params?.bill_address?.tax_invoice_type ?? '',

          first_name: params?.bill_address?.first_name ?? '',
          last_name: params?.bill_address?.last_name ?? '',

          company_name: params?.bill_address?.company_name ?? '',
          company_code: params?.bill_address?.company_code ?? '',

          phone: params?.bill_address?.phone ?? '',

          address: params?.bill_address?.address ?? '',
          sub_district: params?.bill_address?.sub_district ?? '',
          district: params?.bill_address?.district ?? '',
          province: params?.bill_address?.province ?? '',
          country: params?.bill_address?.country ?? '',
          postcode: params?.bill_address?.postcode ?? '',
        }
      : null,

    bill_address: {
      first_name: params?.address?.first_name ?? '',
      last_name: params?.address?.last_name ?? '',
      company_code: params?.address?.company_code ?? null,
      company_name: params?.address?.company_name ?? null,
      phone: params?.address?.phone ?? '',
      tax_id: params?.address?.tax_id ?? null,
      address: params?.address?.address ?? '',
      sub_district: params?.address?.sub_district ?? '',
      district: params?.address?.district ?? '',
      province: params?.address?.province ?? '',
      postcode: params?.address?.postcode ?? '',
    },

    paid_at: params.paid_at ?? null,
    total_price: params.total_price ?? 0,
    deposit: params.deposit ?? 0,
    remaining: params.remaining ?? 0,
    coupon_used: params.coupon_used ?? null,
    discount: params.discount ?? 0,
    before_discount: params.before_discount ?? 0,
    shipping_date: params.shipping_date ?? '',
    special_discount_title: params.special_discount_title ?? null,

    is_barcode: params.is_barcode ?? false,
    is_deposit: params.is_deposit ?? false,
    is_delivery: params.is_delivery ?? false,
    is_pay_at_store: params.is_pay_at_store ?? false,
    is_show_pay_at_store: params.is_show_pay_at_store ?? false,
    is_pay_at_store_completed:
      params.is_pay_at_store_completed ?? false,
    is_can_extend_pay_at_store:
      params.is_can_extend_pay_at_store ?? false,

    pay_at_store_detail: params.pay_at_store_detail ?? null,
    status: params.status ?? null,
    payment_type: params.payment_type ?? null,
    pay_at_store_expire_at: params.pay_at_store_expire_at ?? null,
    pay_at_store_expire_at_timestamp_bkk:
      params.pay_at_store_expire_at_timestamp_bkk ?? null,
    product_main: {
      name: params.product_main.name ?? null,
      image_url: params.product_main.image_url ?? null,
      price: params.product_main.price ?? null,
      sku: params.product_main.sku ?? null,
    },
    bundle_products: Array.isArray(params.bundle_products)
      ? params.bundle_products.map(bundle => {
          return {
            name: bundle.detail.name ?? null,
            type: bundle.detail.type ?? null,
            image_url: bundle.detail.image_url ?? null,
            sku: bundle.sku ?? null,
            price_srp: bundle.detail.price_srp ?? 0,
            price_ship: bundle.detail.price_ship ?? 0,
            price_store: bundle.detail.price_store ?? 0,
            price_deposit: bundle.detail.price_deposit ?? 0,
          }
        })
      : [],

    staff_branch_code: params.staff_branch_code ?? null,
    staff_code: params.staff_code ?? null,
  }
}
