import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret,
  faAngleRight,
  faAngleLeft,
  faFile,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faFacebookF,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faUserSecret,
  faFacebook,
  faFacebookF,
  faTwitter,
  faInstagram,
  faAngleRight,
  faAngleLeft,
  faFile,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
