import axios from '../config/api'
const campaignType = process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'

const state = () => ({
  current: null,
  campaignList: [],
  isLoading: false,
  isError: false,
})

const getters = {}

const actions = {
  update({ commit }, payload) {
    commit('update', payload)
  },
  getCampaignList({ commit }) {
    commit('setCampaignList')
    commit('setCampaignError', false)
    axios
      .get(`/campaign-list?type=${campaignType}`)
      .then(response => {
        commit('setCampaignSuccess', response.data.data)
      })
      .catch(() => {
        commit('setCampaignListError', true)
      })
  },
}

const mutations = {
  setCampaignList(state) {
    state.campaignList = []
    state.isLoading = true
  },
  setCampaignSuccess(state, item) {
    state.campaignList = item
    state.isLoading = false
  },
  setCampaignError(state, value) {
    state.campaignList = value
    state.isLoading = false
  },
  update(state, value) {
    state.current = value
    state.isLoading = true
  },
  updateLoading(state, value) {
    state.isLoading = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
