import axios from '../config/api'
import Vue from 'vue'
import moment from 'moment'
import { orderDetailTransform } from '@/transforms/orderTransform'
import router from '@/router'

const campaignType = process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'

const state = () => ({
  data: [],
  payload: null,
  isError: false,
  isExpired: false,
  isLoading: false,
  submit: {
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
})

// getters
const getters = {}

// actions
const actions = {
  async getOrder({ commit }, payload) {
    commit('setOrder')
    await axios
      .get(`/orders/${payload}`)
      .then(response => {
        commit('setOrderSuccess', response.data.data)
      })
      .catch(error => {
        if (error.response.status === 500) {
          router.push('/404')
        }
        commit('setOrderError')
      })
  },

  async getOrderLists({ commit }) {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tokenOTP')}`,
      },
    }

    commit('getOrderListsRequest')
    await axios
      .get(`/user/order-history/${campaignType}`, headers)
      .then(response => {
        commit('getOrderListsRequestSuccess', response.data.data)
      })
      .catch(() => {
        commit('getOrderListsRequestFailure')
        localStorage.removeItem('tokenOTP')
      })
  },

  getOrderDetail({ commit }, payload) {
    commit('setOrder')
    axios
      .get(`/user/order-detail/${payload}`)
      .then(response => {
        commit('setOrderSuccess', response.data.data)
      })
      .catch(() => {
        commit('setOrderError')
      })
    // }
  },

  async extendTimePayAtStore({ commit }, payload) {
    commit('submitRequest')
    await axios
      .post('/user/extend-pay-at-store', payload)
      .then(() => {
        commit('submitRequestSuccess')

        Vue.$toast.success('ขยายเวลาชำระเงินสำเร็จ', {
          duration: 5000,
          position: 'top',
        })
      })
      .catch(error => {
        commit('submitRequestFailure')

        let message = error.response.data.message
          ? error.response.data.message
          : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'

        Vue.$toast.error(message, {
          duration: 5000,
          position: 'top',
        })
      })
  },

  isPayAtStoreExpired({ commit }) {
    commit('setOrderExpired')
  },

  async confirmPayment({ commit }, payload) {
    commit('submitRequest')

    const accessToken = Vue.prototype.$cookies.get('token')
    // localStorage.getItem('token')

    if (!accessToken) {
      Vue.$toast.error('กรุณาเข้าสู่ระบบ', {
        duration: 5000,
        position: 'top',
      })
      return
    }

    await axios
      .post('/orders/stamp-pay-at-store', payload, {
        headers: {
          Authorization: `Bearer ' ${accessToken}`,
        },
      })
      .then(() => {
        commit('submitRequestSuccess')

        Vue.$toast.success('ยืนยันการชำระเงินสำเร็จ', {
          duration: 5000,
          position: 'top',
        })
      })
      .catch(error => {
        commit('submitRequestFailure')

        let message = error.response.data.message
          ? error.response.data.message
          : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'

        Vue.$toast.error(message, {
          duration: 5000,
          position: 'top',
        })
      })
  },
}

// mutations
const mutations = {
  setOrder(state) {
    state.isLoading = true
    state.isError = false
  },
  setOrderSuccess(state, payload) {
    state.isLoading = false
    state.data = orderDetailTransform(payload)
    state.isError = false
  },
  setOrderError(state) {
    state.isLoading = false
    state.isError = true
  },

  getOrderListsRequest(state) {
    state.isLoading = true
    state.isError = false
  },
  getOrderListsRequestSuccess(state, payload) {
    state.isLoading = false
    state.data = payload
    state.isError = false
  },
  getOrderListsRequestFailure(state) {
    state.isLoading = false
    state.isError = true
  },
  submitRequest(state) {
    state.submit.isLoading = true
    state.submit.isError = false
    state.submit.isSuccess = false
  },
  submitRequestSuccess(state) {
    state.submit.isLoading = false
    state.submit.isSuccess = true
  },
  submitRequestFailure(state) {
    state.submit.isLoading = false
    state.submit.isError = true
  },
  resetSubmit(state) {
    state.submit.isLoading = false
    state.submit.isError = false
    state.submit.isSuccess = false
  },
  setOrderExpired(state) {
    state.isExpired =
      moment().isAfter(
        moment.unix(state.data.pay_at_store_expire_at_timestamp_bkk),
      ) ?? false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
