const state = () => ({
  data: [],
  payload: null,
  key: null,
  isError: false,
  isLoading: false,
  isValidate: false,
  reset: false,
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setValidate(state) {
    state.isLoading = false
    state.isValidate = true
  },
  setKey(state, payload) {
    state.isLoading = false
    state.key = payload
  },
  setReset(state, payload) {
    state.isLoading = false
    state.reset = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
