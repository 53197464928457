<template>
  <nav
    class="nav fixed"
    :style="[
      {
        top: ['Cart', 'Thank', 'OrderDetail'].includes($route.name)
          ? 0
          : `${styleTop}px`,
      },
    ]"
  >
    <v-container
      :class="[
        'nav-container',
        { 'header-checkout': $route.name === 'Cart' },
      ]"
    >
      <div @click="goIndex" class="d-flex items-center">
        <img height="36px" :src="logo" />
        <img
          v-if="!isBNN"
          src="@/assets/premium-partner-logo.svg"
          class="premium-partner-img"
        />
      </div>
    </v-container>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import isBNN from '@/config/isBnn'

export default {
  name: 'Header',

  data() {
    return {
      isDev: process.env.VUE_APP_ENV !== 'production',
      styleTop: 0,
      prevScroll: 0,
    }
  },

  computed: {
    ...mapState({
      campaign: state => state?.campaign?.current?.data,
    }),

    campaignName() {
      const notShow = ['PreRegister', 'CountDown']
      let hasNotShow = false
      for (const item of notShow) {
        if (this.$route?.name?.indexOf(item) > -1) {
          hasNotShow = true
          break
        }
      }
      return !hasNotShow ? this.campaign?.name ?? '' : ''
    },

    logo() {
      return isBNN
        ? require('@/assets/bnn/bnn.svg')
        : require('@/assets/logocom7.svg')
    },
  },

  methods: {
    onWindowScroll() {
      if (this.prevScroll > window.scrollY || window.scrollY <= 0) {
        this.styleTop = 0
      } else {
        this.styleTop = -145
      }
      this.prevScroll = window.scrollY
    },

    goIndex() {
      this.$store.commit('cart/resetCouponCode')

      if (this.$route?.params?.slug) {
        this.$router
          .push({
            name: 'ProductDetail',
            params: { slug: this.$route?.params?.slug },
          })
          .catch(() => true)
      } else if (localStorage.getItem('productLatestVisit')) {
        this.$router
          .push({
            name: 'ProductDetail',
            params: {
              slug: localStorage.getItem('productLatestVisit'),
            },
          })
          .catch(() => true)
      } else {
        this.$router
          .push({
            path: '/',
          })
          .catch(() => true)
      }
    },
  },

  mounted() {
    this.prevScroll = window.pageYOffset
    window.addEventListener('scroll', this.onWindowScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll)
  },
}
</script>

<style lang="stylus">

.nav-container.header-checkout {
    padding: 0 38px !important
}

.nav
  width: 100%
  margin: 0
  z-index: 10
  background-color: white
  box-shadow: inset 0 -.03125rem #d2d2d7
  height: $header-height-mobile
  display: flex;
  align-items: center;
  @media screen and (min-width: 1024px)
    height: $header-height

.nav-container
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px !important;
    @media screen and (min-width: 1024px)
        padding: 28px 16px !important;
        justify-content: start;
    @media screen and (min-width: 1251px)
        padding: 28px 0 !important;
        justify-content: start;
.fixed
  position: fixed
  transition: top 0.2s;

@media small
  .logo
    height: 30px

@media medium
  .logo
    height: 30px

  .text-head
    font-size: 1rem !important

.text-color-bnn
  color: $color-bnn

// $logo-container-height: 40px;
// $space-mobile-gap: 16px;
// $space-desktop-l-gap: 40px; // 2.5 x space-mobile-gap
// $image-mobile-height: 34px;
// $image-tablet-height: 36px;

.premium-partner-img {
    height: 34px;
    margin-left: 16px;

    @media screen and (min-width: 1252px) {
        margin-left: 40px;
    }
}
</style>
