var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"nav fixed",style:([
    {
      top: ['Cart', 'Thank', 'OrderDetail'].includes(_vm.$route.name)
        ? 0
        : `${_vm.styleTop}px`,
    },
  ])},[_c('v-container',{class:[
      'nav-container',
      { 'header-checkout': _vm.$route.name === 'Cart' },
    ]},[_c('div',{staticClass:"d-flex items-center",on:{"click":_vm.goIndex}},[_c('img',{attrs:{"height":"36px","src":_vm.logo}}),(!_vm.isBNN)?_c('img',{staticClass:"premium-partner-img",attrs:{"src":require("@/assets/premium-partner-logo.svg")}}):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }