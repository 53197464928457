<template>
  <div :class="['footer', { 'studio-7': !isBnn }]">
    {{ text }}
  </div>
</template>

<script>
import isBnn from '@/config/isBnn'

export default {
  data() {
    return {
      isBnn,
    }
  },
  computed: {
    text() {
      return isBnn
        ? 'Copyright © 2022 Bnn. All rights reserved.'
        : 'Copyright © 2024 Studio7Thailand. All rights reserved.'
    },
  },
}
</script>

<style lang="stylus" scoped>
.footer
    background: black
    color: white
    height: 56px
    // margin-top: 2.5rem
    display: flex
    justify-content: center
    align-items: center
    width 100%
    font-size: 14px
    font-weight: bold
    text-align: center
    @media screen and (max-width: 768px)
        // margin-bottom: 150px

.footer.studio-7
    background: white
    color: black
    font-weight: normal
    font-size: 10px
    border-top: 1px solid $color-gray85
    height: fit-content
    padding 16px
    color: rgba(18, 18, 18, .75);
    text-align: center;
    font-size: .6875rem;
    font-weight: 400;
    letter-spacing: .7px;
</style>
