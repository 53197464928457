import axios from '../config/api'
import router from '@/router'
import Vue from 'vue'
import Cookies from 'js-cookie'

const state = () => ({
  data: null,
  uuid: null,
  payload: null,
  isError: false,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
  accessToken: Cookies.get('token') || null,
  isLoggedIn: !!Cookies.get('token'),
})

// getters
const getters = {
  isLoggedIn: state => state.isLoggedIn,
  isLoading: state => state.isLoading,
}

// actions
const actions = {
  async otpRequest({ commit }, payload) {
    commit('otpRequest')
    await axios
      .post('/otp', payload)
      .then(response => {
        commit('otpRequestSuccess', response.data)
      })
      .catch(() => {
        commit('otpRequestFailure')
      })
  },

  async verifyOtp({ commit }, payload) {
    commit('verifyOtpRequest')
    await axios
      .post('/otp/verify/user', payload)
      .then(response => {
        commit('verifyOtpRequestSuccess')
        localStorage.setItem('tokenOTP', response.data?.data?.token)
      })
      .catch(() => {
        commit('verifyOtpRequestFailure')
      })
  },

  async login({ commit }, payload) {
    commit('authenticationRequest')

    const formData = new FormData()

    await Object.entries(payload).forEach(([key, value]) =>
      formData.append(key, value),
    )

    await axios
      .post(process.env.VUE_APP_ITEASY_LOGIN_API_URL, formData)
      .then(async response => {
        await commit('authenticationSuccess', response.data.data)

        Vue.$toast.success('เข้าสู่ระบบสำเร็จ', {
          duration: 5000,
          position: 'top',
        })

        let redirect = '/'
        let productLatestVisit =
          localStorage.getItem('productLatestVisit') ?? null

        if (productLatestVisit) {
          redirect = `/stock/live/${productLatestVisit}`
        }

        router.push(localStorage.getItem('redirectPage') || redirect)
      })
      .catch(error => {
        commit('authenticationFailure')

        let message = error.response.data.error
          ? error.response.data.error
          : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'

        Vue.$toast.error(message, {
          duration: 5000,
          position: 'top',
        })
      })
  },
  async logout({ commit }) {
    commit('authenticationFailure')
  },
}

// mutations
const mutations = {
  otpRequest(state) {
    state.isLoading = true
    state.isSuccess = false
    state.isError = false
  },
  otpRequestSuccess(state, payload) {
    state.uuid = payload.data.uuid
    state.isLoading = false
    state.isSuccess = true
    state.isError = false
  },
  otpRequestFailure(state) {
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
  },
  verifyOtpRequest(state) {
    state.isLoading = true
    state.isSuccess = false
    state.isError = false
  },
  verifyOtpRequestSuccess(state) {
    state.isLoading = false
    state.isSuccess = true
    state.isError = false
  },
  verifyOtpRequestFailure(state) {
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
  },
  authenticationRequest(state) {
    state.isLoading = true
    state.isSuccess = false
    state.isError = false
  },
  authenticationSuccess(state, payload) {
    state.isLoading = false
    state.isSuccess = true
    state.isError = false
    state.accessToken = payload.access_token
    state.isLoggedIn = true

    // Set cookies expire in 1 day
    // Set cookies expire in 30 seconds 1/4800 for testing
    Cookies.set('token', payload.access_token, {
      expires: 1,
    })
    Cookies.set('sc', payload.user_profile?.emp_code, {
      expires: 1,
    })
    Cookies.set('bc', payload.user_profile?.org_unit?.store_id, {
      expires: 1,
    })

    let isInnovation =
      payload.user_profile?.org_unit?.store_code == 'H8070'

    if (isInnovation) {
      Cookies.set('isinno', true, {
        expires: 1,
      })
    }
  },
  authenticationFailure(state) {
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
    state.accessToken = null
    state.isLoggedIn = false

    let keys = ['token', 'sc', 'bc', 'isinno']

    keys.forEach(key => {
      Cookies.remove(key)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
