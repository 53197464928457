import axios from '../config/api'
import { deepCopy } from '@/utils/utils'
// import branchTypes from '@/config/branchTypes'

const state = () => ({
  delivery: 'delivery',
  cartSummaryData: null,
  showSummary: true,
  coupon: {
    code: '',
    data: null,
    autoAddToCart: null,
    discountAmount: 0,
    isError: false,
    message: '',
    status: 200,
  },
  isLoading: false,
})

// getters
const getters = {
  haveDeposit: state => {
    return state.delivery === false
  },

  priceDeposit: (state, getters, root) => {
    const sku = root.model.selectSKU
    return sku?.price_deposit || 0
  },

  priceBundle: (state, getters, root) => {
    const sku = root.model.selectSKU
    const selecteds = root.bundle.selects
    const delivery = root.cart.delivery

    const bundles = sku?.bundle_product
    const amount = bundles?.reduce((crr, item) => {
      if (selecteds[item.sku]) {
        crr +=
          parseInt(selecteds[item.sku]) *
          parseInt(delivery ? item.price_ship : item.price_store)
      }
      return crr
    }, 0)

    return amount
  },

  priceAdditional: (state, getters, root) => {
    const selecteds = root.bundle.selects
    const delivery = root.cart.delivery

    const additionalProduct = deepCopy(root.model.additionalProduct)

    const amount = additionalProduct?.reduce((crr, item) => {
      item.products.map(p => {
        if (selecteds[p.sku]) {
          crr +=
            parseInt(selecteds[p.sku]) *
            parseInt(delivery ? p.price_ship : p.price_store)
        }
      })
      return crr
    }, 0)
    return amount ?? 0
  },

  priceDelivery: (state, getters, root) => {
    const sku = root.model.selectSKU
    const selecteds = root.bundle.delivery
    const delivery = root.cart.delivery

    const bundles = sku?.bundle_product
    const amount = bundles?.reduce((crr, item) => {
      if (selecteds[item.sku]) {
        crr +=
          parseInt(selecteds[item.sku]) *
          parseInt(delivery ? item.price_ship : item.price_store)
      }
      return crr
    }, 0)

    return amount
  },

  priceSummary: (state, getters, root) => {
    const sku = root.model?.selectSKU

    // const discountAmount =
    //   root.branchStore.branchType ===
    //     branchTypes.BRANCH_TYPE_DEPOSIT &&
    //   root.cart.delivery === 'store'
    //     ? 0
    //     : state.coupon.discountAmount
    const discountAmount = state.coupon.discountAmount

    const priceSummary =
      parseInt(sku?.price) +
      getters.priceBundle +
      getters.priceDelivery +
      getters.priceAdditional -
      discountAmount

    return priceSummary
  },

  discountOnTop: (_, getters, __, rootGetters) => {
    const paymentType =
      rootGetters?.['paymentType/getCurrentPaymentType'] ?? null
    const discountOnTopPercent =
      paymentType?.on_top_discount_percent ?? 0
    const discountOnTopAmount =
      (getters.priceSummary * discountOnTopPercent) / 100
    return paymentType ? discountOnTopAmount : 0
  },

  cartItems: (state, getters, root) => {
    const sku = root?.model?.selectSKU
    const selecteds = root?.bundle?.selects
    const bundles = sku?.bundle_product
    let additional = []

    deepCopy(root?.model?.additionalProduct)?.map(a => {
      a.products?.map(item => {
        item.count = selecteds[item.sku] || 0
        item.amount = parseInt(item.price) * parseInt(item.count)
        additional.push({
          sku: item.sku,
          name: item.name,
          price: item.price,
          price_srp: item.price_srp,
          price_ship: item.price_ship,
          price_store: item.price_store,
          amount: item.amount,
          count: item.count,
          imageUrl: item.image_url,
          active: item.active,
          type: 'additional_product',
        })
      })
    })

    const items = deepCopy(bundles)
      ?.map(b => {
        b.count = selecteds[b.sku] || 0
        b.amount = parseInt(b.price) * parseInt(b.count)
        return {
          sku: b.sku,
          name: b.name,
          price: b.price,
          price_srp: b.price_srp,
          price_ship: b.price_ship,
          price_store: b.price_store,
          amount: b.amount,
          count: b.count,
          imageUrl: b.image_url,
          active: b.active,
          type: b.type === 'warranty' ? b.type : 'bundle',
          isFreeBie: b.type === 'free',
        }
      })
      ?.filter(b => b.count > 0)

    // sort main_product to the first and freebie to the end
    return [
      {
        sku: sku?.sku,
        name: sku?.name,
        price: sku?.price,
        amount: sku?.price,
        price_srp: sku?.price_srp,
        price_ship: sku?.price,
        price_store: sku?.price,
        imageUrl: sku?.image_url,
        count: 1,
        type: 'main_product',
      },
      ...items,
      ...additional?.filter(b => b.count > 0),
    ].sort((a, b) => {
      if (a.isFreeBie) return 1
      if (b.isFreeBie) return -1
      if (a.type === 'main_product') return -1
      if (b.type === 'main_product') return 1
      return 0
    })
  },

  productItem: (state, getters, root) => {
    return root.model.selectSKU
  },

  bundleItems: (state, getters, root) => {
    const selecteds = root.bundle.selects
    const keys = Object.keys(selecteds)
    return keys.map(k => ({
      sku: k,
      unit: selecteds[k],
    }))
  },
  deliveryItems: (state, getters, root) => {
    const selecteds = root.bundle?.delivery
    const keys = Object.keys(selecteds)
    return keys?.map(k => ({
      sku: k,
      unit: selecteds?.[k],
    }))
  },
}

// actions
const actions = {
  showSummary({ commit }, payload) {
    if (screen.width <= 760) {
      commit('showSummary', payload)
    }
  },

  async checkCouponCode({ commit }, payload) {
    try {
      commit('resetCouponCode')
      const {
        productId,
        couponCode,
        bundleItems,
        paymentTypeId,
      } = payload
      const jsonData = {
        product_id: productId,
        code: couponCode,
        bundle_selected: bundleItems?.filter(i => i?.unit > 0) ?? [],
        payment_type_id: paymentTypeId,
      }
      // const res = await axios.get(
      //   `/coupon-product/${productId}/code/${couponCode}`,
      // )
      const res = await axios.post(`/redeem-coupon`, jsonData)
      const { data } = res
      commit('setCouponCode', data?.data)
    } catch (e) {
      commit('setCouponCodeError', e.response.data)
    }
  },

  async cartSummary({ commit }, payload) {
    try {
      // delete key if value is null
      Object.keys(payload).forEach(
        key => payload[key] == null && delete payload[key],
      )
      commit('setLoading', true)
      commit('setCartSummaryData')
      const res = await axios.post(`/cart/summary`, payload)
      const { data } = res
      commit('setCartSummaryData', data?.data)
      commit('setLoading', false)
    } catch (e) {
      commit('setCartSummaryDataError')
      commit('setLoading', false)
    }
  },
}

// mutations
const mutations = {
  showSummary(state, payload) {
    state.showSummary = payload
  },
  setLoading(state, value) {
    state.isLoading = value
  },
  delivery(state, value) {
    state.delivery = value
  },
  resetCouponCode(state) {
    state.coupon = {
      code: '',
      data: null,
      autoAddToCart: null,
      discountAmount: 0,
      isError: false,
    }
  },
  async setCouponCode(state, value) {
    state.coupon = {
      code: value?.code,
      autoAddToCart: value?.auto_add_to_cart,
      discountAmount: +value?.discount_amount,
      message: value?.message,
      status: value?.status,
      isError: false,
    }
  },
  setCouponCodeError(state, value) {
    state.coupon = {
      code: '',
      data: value?.data,
      discountAmount: 0,
      message: value?.message,
      status: value?.status,
      isError: true,
    }
  },
  setCartSummaryData(state, value) {
    state.cartSummaryData = value
  },
  setCartSummaryDataError(state) {
    state.cartSummaryData = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
