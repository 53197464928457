import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import {
//   Swiper as SwiperClass,
//   Pagination,
//   Navigation,
//   Thumbs,
// } from 'swiper/swiper.esm.js'

// Swiper modules
// SwiperClass.use([Pagination, Navigation, Thumbs])

// import style
// import 'swiper/swiper-bundle.min.css'
// import style
import 'swiper/css/swiper.css'

Vue.use(
  VueAwesomeSwiper /* { default options with global component } */,
)
