<template>
  <div class="ribbon">
    <div>
      <div>{{ VUE_APP_ENV }}</div>
      <div>{{ version }}</div>
    </div>
  </div>
</template>

<script>
import version from '@/config/version'
export default {
  name: 'Ribbon',

  computed: {
    VUE_APP_ENV() {
      return process.env.VUE_APP_ENV?.toUpperCase()
    },
  },

  data() {
    return {
      version: version,
    }
  },
}
</script>

<style lang="stylus">
.ribbon
    opacity 0.2
    display flex
    justify-content center
    align-items center
    text-align center
    top 10px
    right -90px
    position fixed
    z-index 50
    color red
    font-size 2rem
    border-bottom: 4px solid red;
    width:300px;
    height: 100px;
    background:#fff;
    box-shadow: 4px 4px 4px $color-green;
    -webkit-text-stroke: 2px black;
    font-size: 1rem

    /* Safari */
    -webkit-transform: rotate(45deg);
    /* Firefox */
    -moz-transform: rotate(45deg);
    /* IE */
    -ms-transform: rotate(45deg);
    /* Opera */
    -o-transform: rotate(45deg);
</style>
