import axios from '../config/api'

const state = () => ({
  data: null,
  payload: null,
  isError: false,
  isLoading: false,
  coupon: {
    code: '',
    discount: 0,
    finalAmount: 0,
    remaining: 0,
    isError: false,
    message: '',
    status: 200,
  },
})

// getters
const getters = {}

// actions
const actions = {
  async checkCouponCode({ commit }, payload) {
    try {
      commit('resetCouponCode')
      const { orderId, couponCode } = payload
      const jsonData = {
        order_id: orderId,
        coupon_code: couponCode,
      }
      const res = await axios.post(
        `/payment/remaining/redeem-coupon`,
        jsonData,
      )
      const { data } = res
      commit('setCouponCode', {
        ...data,
        code: couponCode,
      })
    } catch (e) {
      commit('setCouponCodeError', e.response.data)
    }
  },

  async getRemainingAmount({ commit }, { orderId }) {
    commit('setLoading', true)
    try {
      const result = await axios.get(
        `/payment/remaining-amount/${orderId}`,
      )
      commit('setData', result.data.data)
    } catch (e) {
      commit('setError')
    }
  },
}

// mutations
const mutations = {
  setLoading(state, payload) {
    state.isLoading = payload
  },

  setData(state, payload) {
    state.isLoading = false
    state.isError = false
    state.data = payload
  },

  setError(state) {
    state.isLoading = false
    state.isError = true
    state.data = null
  },

  resetCouponCode(state) {
    state.coupon = {
      code: '',
      discount: 0,
      finalAmount: 0,
      remaining: 0,
      message: '',
      isError: false,
    }
  },
  setCouponCode(state, value) {
    state.coupon = {
      code: value?.code,
      discount: value?.data?.discount,
      finalAmount: value?.data?.final_amount,
      remaining: value?.data?.remaining,
      message: value?.message,
      status: value?.status,
      isError: false,
    }
  },
  setCouponCodeError(state, value) {
    state.coupon = {
      code: '',
      discount: 0,
      finalAmount: 0,
      remaining: 0,
      message: value?.message,
      status: value?.status,
      isError: true,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
