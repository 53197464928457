import Vue from 'vue'
import Cookies from 'js-cookie'

const CookiePlugin = {
  install(Vue) {
    Vue.prototype.$cookies = Cookies
  },
}

Vue.use(CookiePlugin)
