import Vue from 'vue'
import axios from '../config/api'

const state = () => ({
  data: [],
  isError: false,
  isLoading: false,
})

// getters
const getters = {}

// actions
const actions = {
  async submit({ commit }, payload) {
    try {
      const response = await axios.post(`/pre-register`, payload, {
        headers: { 'Content-Type': 'application/json' },
      })
      commit('setPreRegisterSuccess', response.data.data)
      Vue.$toast.success('ขอบคุณสำหรับการลงทะเบียน', {
        // override the global option
        duration: 3000,
        position: 'top',
      })
    } catch {
      Vue.$toast.error('ส่งข้อมูลไม่สำเร็จ โปรดลองใหม่่อีกครั้ง', {
        // override the global option
        duration: 3000,
        position: 'top',
      })
      commit('setPreRegisterError')
    }
  },
}

// mutations
const mutations = {
  setPreRegister(state) {
    state.isLoading = true
    state.isError = false
  },
  setPreRegisterSuccess(state, payload) {
    state.isLoading = false
    state.data = payload
    state.isError = false
  },
  setPreRegisterError(state) {
    state.isLoading = false
    state.data = null
    state.isError = true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
