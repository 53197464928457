const state = () => ({
  kbankForm: {},
  isLoading: false,
})

// getters
const getters = {}

const actions = {
  setFormKBank({ commit }, payload) {
    commit('setFormKBank', payload)
  },
}

// mutations
const mutations = {
  setFormKBank(state, payload) {
    state.kbankForm = payload
    // state.step =
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
