var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{on:{"click":_vm.showSummary}},[_c('Header'),_c('router-view'),_c('Error'),(_vm.isDev)?_c('Ribbon'):_vm._e(),(
      [
        'ProductDetail',
        'CountDown',
        'Campaign',
        'Error',
        'NotFound',
      ].includes(_vm.$route.name)
    )?_c('FooterFullStudio7',{style:({
      'margin-bottom': ['ProductDetail'].includes(_vm.$route.name)
        ? _vm.elementHeight + 'px'
        : '0px',
    })}):_vm._e(),(
      ![
        'Cart',
        'Thank',
        'OrderDetail',
        'ProductDetail',
        'CountDown',
        'Campaign',
        'Error',
        'NotFound',
      ].includes(_vm.$route.name)
    )?_c('Footer',{style:({
      'margin-bottom': ['ProductDetail'].includes(_vm.$route.name)
        ? _vm.elementHeight + 'px'
        : '0px',
    })}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }