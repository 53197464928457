import axios from '../config/api'
const campaignType = process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'

const state = () => ({
  data: null,
  payload: null,
  isError: false,
  isLoading: false,
})

// getters
const getters = {}

// actions
const actions = {
  async getStaticPage({ commit }, payload) {
    try {
      commit('setLoading', true)
      const res = await axios.get(
        `/static-page/${campaignType}/${payload}`,
      )
      commit('setData', res.data.data)
    } catch (e) {
      commit('setError', e.response.data)
    }
  },
}

// mutations
const mutations = {
  setLoading(state, payload) {
    state.isLoading = payload
  },

  setData(state, payload) {
    state.isLoading = false
    state.isError = false
    state.data = payload
  },

  setError(state) {
    state.isLoading = false
    state.isError = true
    state.data = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
