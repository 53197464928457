import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/fontawesome'
import './plugins/filters'
import './plugins/sentry'
import './plugins/countdown'
import './plugins/vueSwiper'
import './plugins/vueMoment'
import './plugins/buddhisYear'
import './plugins/vueToast'
import './plugins/cookies'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '@/assets/css/_htmlContent.styl'
import { Html5Qrcode } from 'html5-qrcode'
import CryptoJS from 'crypto-js'

Vue.config.productionTip = false
Vue.config.silent = ['justify']

Vue.use(require('html5-qrcode'), Html5Qrcode)
Vue.use(require('crypto-js'), CryptoJS)

new Vue({
  router,
  store,
  vuetify,
  created() {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
