import Vue from 'vue'
import axios from '../config/api'
import CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'

const state = () => ({
  accessToken: Cookies.get('token') || null,
  data: {},
  member: {
    data: null,
    uuid: null,
    isError: false,
    errorMessage: null,
    isSuccess: false,
    isVerifySuccess: false,
    isLoading: false,
  },
})

// getters
const getters = {
  // eslint-disable-next-line no-unused-vars
  getData: state => {
    return state.data
  },
}

// actions
const actions = {
  async otpMemberRequest({ commit }, payload) {
    commit('otpMemberRequest')
    await axios
      .post('/user/member-request-otp', payload)
      .then(response => {
        commit('otpMemberRequestSuccess', response.data)
      })
      .catch(error => {
        commit('otpMemberRequestFailure', error.response.data.message)

        let message = error.response.data.message
          ? error.response.data.message
          : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'

        if (error.response.status == 406) {
          message = 'ท่านยังไม่เป็นสมาชิก 7 Club Member'
        }

        Vue.$toast.warning(message, {
          duration: 5000,
          position: 'top',
        })
      })
  },
  async verifyOtpMember({ commit }, payload) {
    commit('verifyOtpMemberRequest')

    await axios
      .post('/user/member-verify-otp', payload)
      .then(async response => {
        try {
          await commit('verifyOtpMemberRequestSuccess', response.data)
        } catch {
          // console.log('error verifyOtpMember:', error)
        }

        Vue.$toast.success('ดึงข้อมูลสมาชิกสำเร็จ', {
          duration: 5000,
          position: 'top',
        })
      })
      .catch(error => {
        commit(
          'verifyOtpMemberRequestFailure',
          error.response.data.message,
        )

        // let message = error.response.data.message
        //   ? error.response.data.message
        //   : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'

        // Vue.$toast.error(message, {
        //   duration: 5000,
        //   position: 'top',
        // })
      })
  },
  async getMemberByKey({ commit }, payload) {
    commit('getMemberByKeyRequest')

    const accessToken = Cookies.get('token') || null
    // localStorage.getItem('token')

    if (!accessToken) {
      Vue.$toast.error('กรุณาเข้าสู่ระบบ', {
        duration: 5000,
        position: 'top',
      })
      return
    }

    await axios
      .post('/user/member-info', payload, {
        headers: {
          Authorization: `Bearer ' ${accessToken}`,
        },
      })
      .then(async response => {
        await commit('getMemberByKeyRequestSuccess', response.data)

        Vue.$toast.success('สแกนข้อมูลสมาชิกสำเร็จ', {
          duration: 5000,
          position: 'top',
        })
      })
      .catch(error => {
        commit(
          'getMemberByKeyRequestFailure',
          error.response.data.message,
        )

        let message = error.response.data.message
          ? error.response.data.message
          : 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'

        Vue.$toast.error(message, {
          duration: 5000,
          position: 'top',
        })
      })
  },
  resetVerifyOtpMember({ commit }) {
    commit('verifyOtpMemberRequestReset')
  },
  resetMember({ commit }) {
    commit('resetMemberRequest')
  },
  fetchData({ commit }) {
    try {
      commit('decryptedData')
    } catch {
      // console.log('error fetchData:', error)
    }
  },
  setData({ commit }, payload) {
    commit('encryptedData', payload)
  },
}

// mutations
const mutations = {
  otpMemberRequest(state) {
    state.member = {
      data: null,
      uuid: null,
      isError: false,
      errorMessage: null,
      isSuccess: false,
      isVerifySuccess: false,
      isLoading: true,
    }
  },
  otpMemberRequestSuccess(state, payload) {
    state.member = {
      data: null,
      uuid: payload.data.uuid,
      isError: false,
      errorMessage: null,
      isSuccess: true,
      isVerifySuccess: false,
      isLoading: false,
    }
  },
  otpMemberRequestFailure(state, payload) {
    state.member = {
      data: null,
      uuid: null,
      isError: true,
      errorMessage: payload,
      isSuccess: false,
      isVerifySuccess: false,
      isLoading: false,
    }
  },
  verifyOtpMemberRequest(state) {
    state.member = {
      data: null,
      uuid: state.member.uuid,
      isError: false,
      errorMessage: null,
      isSuccess: false,
      isVerifySuccess: false,
      isLoading: true,
    }
  },
  async verifyOtpMemberRequestSuccess(state, payload) {
    const data = payload.data ? JSON.parse(payload.data) : {}

    const corporate = data?.corporate?.[0] ?? null

    const transformDataForm = await {
      // If have data from API, use it
      first_name: data?.first_name || state.data?.first_name || '',
      last_name: data?.last_name || state.data?.last_name || '',
      customer_identity:
        data?.tax_id || state.data?.customer_identity || '',
      phone: data?.phone || state.data?.phone || '',
      email: data?.email || state.data?.email || '',
      address: data?.address?.address || state.data?.address || '',
      sub_district:
        data?.address?.sub_district || state.data?.sub_district || '',
      district: data?.address?.district || state.data?.district || '',
      province: data?.address?.province || state.data?.province || '',
      postcode: data?.address?.zipcode || state.data?.postcode || '',

      // If have state.data, use it
      need_true_phone: state.data?.need_true_phone || false,
      checkReceipt: state.data?.checkReceipt || false,
      checkTaxPersonal: state.data?.checkTaxPersonal || false,
      checkTaxJuristic: state.data?.checkTaxJuristic || false,
      taxInvoiceType: state.data?.taxInvoiceType || 'PERSONAL', //  'PERSONAL', 'CORPORATION'
      tax_id: corporate?.tax_id || state.data?.tax_id || '',
      tax_company_id: corporate?.tax_id || '',
      taxFirstName:
        corporate?.first_name || state.data?.taxFirstName || '',
      taxLastName:
        corporate?.last_name || state.data?.taxLastName || '',
      taxCompanyName:
        corporate?.company_name || state.data?.taxCompanyName || '',
      taxCompanyCode:
        corporate?.branch_name || state.data?.taxCompanyCode || '',
      taxAddress1:
        corporate?.address?.address || state.data?.taxAddress1 || '',
      taxAddress2: state.data?.taxAddress2 || '',
      taxAddress3: state.data?.taxAddress3 || '',
      taxProvince:
        corporate?.address?.province || state.data?.taxProvince || '',
      taxDistrict:
        corporate?.address?.district || state.data?.taxDistrict || '',
      taxSubDistrict:
        corporate?.address?.sub_district ||
        state.data?.taxSubDistrict ||
        '',
      taxPostcode:
        corporate?.address?.zipcode || state.data?.taxPostcode || '',
      taxPhoneNumber:
        corporate?.phone || state.data?.taxPhoneNumber || '',

      // other
      for_work: state.data?.for_work || false,

      // additional_description
      additional_description: {
        brand: state.data?.additional_description?.brand || '',
        model: state.data?.additional_description?.model || '',
        interest_item:
          state.data?.additional_description?.interest_item || '',
      },

      // Don't send to API
      sameAsDeliveryAddress:
        state.data?.sameAsDeliveryAddress || true, // tax address same as delivery address
    }

    const secretKey = process.env.VUE_APP_PRE_REGISTER_KEY

    const encryptedData = await CryptoJS.AES.encrypt(
      JSON.stringify(transformDataForm),
      secretKey,
    ).toString()

    localStorage.setItem('registered', encryptedData)
    localStorage.setItem('isMember', data.phone)

    // console.log('encryptedData')

    state.member = {
      data: data,
      uuid: state.member?.uuid || null,
      isError: false,
      errorMessage: null,
      isSuccess: true,
      isVerifySuccess: true,
      isLoading: false,
    }
  },
  verifyOtpMemberRequestFailure(state, payload) {
    state.member = {
      data: null,
      uuid: state.member.uuid,
      isError: true,
      errorMessage: payload,
      isSuccess: false,
      isLoading: false,
    }
  },
  verifyOtpMemberRequestReset(state) {
    state.member = {
      data: null,
      uuid: state.member.uuid,
      isError: false,
      errorMessage: null,
      isSuccess: false,
      isVerifySuccess: false,
      isLoading: false,
    }
  },
  resetMemberRequest(state) {
    state.member = {
      data: null,
      uuid: null,
      isError: false,
      errorMessage: null,
      isSuccess: false,
      isVerifySuccess: false,
      isLoading: false,
    }
  },
  getMemberByKeyRequest(state) {
    state.member = {
      data: null,
      uuid: null,
      isError: false,
      errorMessage: null,
      isSuccess: false,
      isLoading: true,
    }
  },
  async getMemberByKeyRequestSuccess(state, payload) {
    const data = payload.data ? JSON.parse(payload.data) : {}

    const corporate = data?.corporate?.[0] ?? null

    const transformDataForm = await {
      // If have data from API, use it
      first_name: data?.first_name || state.data?.first_name || '',
      last_name: data?.last_name || state.data?.last_name || '',
      customer_identity:
        data?.tax_id || state.data?.customer_identity || '',
      phone: data?.phone || state.data?.phone || '',
      email: data?.email || state.data?.email || '',
      address: data?.address?.address || state.data?.address || '',
      sub_district:
        data?.address?.sub_district || state.data?.sub_district || '',
      district: data?.address?.district || state.data?.district || '',
      province: data?.address?.province || state.data?.province || '',
      postcode: data?.address?.zipcode || state.data?.postcode || '',

      // If have state.data, use it
      need_true_phone: state.data?.need_true_phone || false,
      checkReceipt: state.data?.checkReceipt || false,
      checkTaxPersonal: state.data?.checkTaxPersonal || false,
      checkTaxJuristic: state.data?.checkTaxJuristic || false,
      taxInvoiceType: state.data?.taxInvoiceType || 'PERSONAL', //  'PERSONAL', 'CORPORATION'
      tax_id: corporate?.tax_id || state.data?.tax_id || '',
      tax_company_id: corporate?.tax_id || '',
      taxFirstName:
        corporate?.first_name || state.data?.taxFirstName || '',
      taxLastName:
        corporate?.last_name || state.data?.taxLastName || '',
      taxCompanyName:
        corporate?.company_name || state.data?.taxCompanyName || '',
      taxCompanyCode:
        corporate?.branch_name || state.data?.taxCompanyCode || '',
      taxAddress1:
        corporate?.address?.address || state.data?.taxAddress1 || '',
      taxAddress2: state.data?.taxAddress2 || '',
      taxAddress3: state.data?.taxAddress3 || '',
      taxProvince:
        corporate?.address?.province || state.data?.taxProvince || '',
      taxDistrict:
        corporate?.address?.district || state.data?.taxDistrict || '',
      taxSubDistrict:
        corporate?.address?.sub_district ||
        state.data?.taxSubDistrict ||
        '',
      taxPostcode:
        corporate?.address?.zipcode || state.data?.taxPostcode || '',
      taxPhoneNumber:
        corporate?.phone || state.data?.taxPhoneNumber || '',

      // other
      for_work: state.data?.for_work || false,

      // additional_description
      additional_description: {
        brand: state.data?.additional_description?.brand || '',
        model: state.data?.additional_description?.model || '',
        interest_item:
          state.data?.additional_description?.interest_item || '',
      },

      // Don't send to API
      sameAsDeliveryAddress:
        state.data?.sameAsDeliveryAddress || true, // tax address same as delivery address
    }

    const secretKey = process.env.VUE_APP_PRE_REGISTER_KEY
    const encryptedData = await CryptoJS.AES.encrypt(
      JSON.stringify(transformDataForm),
      secretKey,
    ).toString()

    localStorage.setItem('registered', encryptedData)
    localStorage.setItem('isMember', data.phone)

    state.member = {
      data: data,
      uuid: null,
      isError: false,
      errorMessage: null,
      isSuccess: true,
      isLoading: false,
    }
  },
  getMemberByKeyRequestFailure(state, payload) {
    state.member = {
      data: null,
      uuid: null,
      isError: true,
      errorMessage: payload,
      isSuccess: false,
      isLoading: false,
    }
  },
  decryptedData(state) {
    const encryptedData = localStorage.getItem('registered')
    const secretKey = process.env.VUE_APP_PRE_REGISTER_KEY
    let decryptedData = {}

    if (encryptedData) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)

        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      } catch {
        localStorage.removeItem('registered')
        localStorage.removeItem('isMember')
      }
    }

    state.data = decryptedData
  },
  encryptedData(state, payload) {
    const secretKey = process.env.VUE_APP_PRE_REGISTER_KEY

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      secretKey,
    ).toString()

    localStorage.setItem('registered', encryptedData)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
