import Vue from 'vue'
import moment from 'moment'

Vue.filter('yearTh', val => {
  let christianYear = moment(val).format('YYYY')
  let buddhishYear = (parseInt(christianYear) + 543).toString()
  return moment(val)
    .format(buddhishYear)
    .replace(christianYear, buddhishYear)
})

Vue.filter('fullDataTh', val => {
  let christianYear = moment(val).format('YYYY')
  let buddhishYear = (parseInt(christianYear) + 543).toString()
  return `${moment(val).format('DD MMMM')} ${moment(val)
    .format(buddhishYear)
    .replace(christianYear, buddhishYear)}`
})

Vue.filter('fullDateTimeTh', val => {
  let christianYear = moment(val).format('YYYY')
  let buddhishYear = (parseInt(christianYear) + 543).toString()
  return `${moment(val).format('DD MMMM')} 
    ${moment(val)
      .format(buddhishYear)
      .replace(christianYear, buddhishYear)} 
    ${moment(val).format('HH:mm:ss')}`
})
