import axios from '../config/api'
import branchTypes from '../config/branchTypes'
const campaignType = process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'

const state = () => ({
  data: [],
  branchV2: [],
  branchSelect: '',
  branchCodeSelect: null,
  branchType: null,
  dataBranchDeposit: [],
  dataBranchFullPayment: [],
  payload: null,
  isError: false,
  isLoading: false,
})

// getters
const getters = {
  getTimesByBranchName: state => branchName => {
    return (
      state?.data
        ?.filter(items => items === branchName)[0]
        ?.times?.filter(i => i.quota_remain > 0)
        .map(items => {
          if (items.date) {
            items.name = `วันที่ ${items.date} เวลา ${items.time}`
          } else {
            items.name = `เวลา ${items.time}`
          }
          return items
        }) ?? []
    )
  },
  getTimesByBranchId: state => id => {
    return (
      state?.data
        ?.filter(items => items.id === id)[0]
        ?.times?.map(item => {
          if (item.date) {
            item.name = item.active
              ? `วันที่ ${item.date} เวลา ${item.time}`
              : `วันที่ ${item.date} เวลา ${item.time} ( เต็มแล้ว )`
          } else {
            item.name = item.active
              ? `เวลา ${item.time}`
              : `เวลา ${item.time} ( เต็มแล้ว )`
          }

          item.disabled = !item.active
          item.seq = item.disabled ? 1 : 0

          if (item.description) {
            item.name = item.description
          }

          return item
        })
        .sort((a, b) => {
          return a.seq - b.seq
        }) ?? []
    )
  },
  disabledDeliveryStore: state => () => {
    let count = 0
    state?.data?.map(i => {
      i?.times?.map(items => {
        if (items?.active) count++
      })
    })
    return count > 0
  },
  disabledBranchDeposit: state => {
    let count = 0
    state?.dataBranchDeposit?.map(i => {
      i?.times?.map(items => {
        if (items?.active) count++
      })
    })
    return count > 0
  },
  disabledBranchFullPayment: state => {
    let count = 0
    state?.dataBranchFullPayment?.map(i => {
      i?.times?.map(items => {
        if (items?.active) count++
      })
    })
    return count > 0
  },
  getTimesByBranchIdNotActive: state => id => {
    return (
      state?.data
        ?.filter(items => items.id === id)[0]
        ?.times?.filter(i => !i.active)
        .map(items => {
          if (items.date) {
            items.name = `วันที่ ${items.date} เวลา ${items.time}`
          } else {
            items.name = `เวลา ${items.time}`
          }

          return items
        })
        .filter(item => item) ?? []
    )
  },

  getProvince: state => {
    const items = state?.data
    const province = items?.map(item => item?.province)
    const unique = [...new Set(province)]
    return unique.sort((a, b) => {
      return a.localeCompare(b)
    })
  },

  getBranchByProvince: state => province => {
    const items = state?.data
    return items?.filter(item => item?.province === province) ?? []
  },

  getProvinceByBranchId: state => id => {
    const items = state?.data
    return (
      items?.filter(item => item?.id === +id)?.[0]?.province ??
      'กรุงเทพ'
    )
  },

  getProvinceByBranchCode: state => code => {
    const items = state?.data

    return (
      items?.find(item => item?.branch_code === +code)?.province ??
      'กรุงเทพ'
    )
  },

  getBranchIdByBranchCode: state => code => {
    const items = state?.data
    return (
      items?.find(item => item?.branch_code === +code)?.id ?? null
    )
  },

  getBranchNameSelected: state => {
    const items = state?.data
    return (
      items?.find(item => item?.id === state?.branchSelect)?.name ||
      null
    )
  },
}

// actions
const actions = {
  getBranch({ commit, state, rootState }) {
    const slug = rootState?.campaign?.current?.data?.slug
    const branchType = state?.branchType
    // if (state.data.length === 0) {
    commit('setBranch')
    axios
      .get(
        `/v2/${campaignType}/branches?campaign=${slug}&branch_type=${branchType}`,
      )
      .then(response => {
        commit('setBranchSuccess', response.data.data)
      })
      .catch(() => {
        commit('setBranchError')
      })
    // }
  },
  getBranchAllType({ commit, state, rootState }, payload) {
    const slug = rootState?.campaign?.current?.data?.slug
    const branchType = state?.branchType
    commit('setBranch')
    if (branchType === branchTypes.BRANCH_TYPE_DEPOSIT) {
      axios
        .get(
          `/v2/${campaignType}/branches?campaign=${slug}&branch_type=${branchTypes.BRANCH_TYPE_FULL_PAYMENT}&product_id=${payload.productId}&admin_quota=${payload.adminQuota}`,
        )
        .then(response => {
          commit('setBranchFullPayment', response.data.data)
          commit('setBranchDeposit', state.data)
        })
        .catch(() => {
          commit('setBranchFullPaymentError')
        })
    }
    if (branchType === branchTypes.BRANCH_TYPE_FULL_PAYMENT) {
      axios
        .get(
          `/v2/${campaignType}/branches?campaign=${slug}&branch_type=${branchTypes.BRANCH_TYPE_DEPOSIT}&product_id=${payload.productId}&admin_quota=${payload.adminQuota}`,
        )
        .then(response => {
          commit('setBranchDeposit', response.data.data)
          commit('setBranchFullPayment', state.data)
        })
        .catch(() => {
          commit('setBranchDepositError')
        })
    }
  },
  async getRefetchBranch(
    // eslint-disable-next-line no-unused-vars
    { commit, dispatch, state, rootState },
    payload,
  ) {
    const slug = rootState?.campaign?.current?.data?.slug
    const branchType = state?.branchType
    commit('setBranch')
    try {
      const response = await axios.get(
        `/v2/${campaignType}/branches?campaign=${slug}&branch_type=${branchType}&product_id=${payload.productId}&admin_quota=${payload.adminQuota}`,
      )
      commit('setBranchSuccess', response.data.data)
      //   dispatch('getBranchAllType', payload)
    } catch {
      commit('setBranchError')
    }
  },
  async getBranchV2({ commit }, payload) {
    const slug = payload.slug
    const branchType = payload?.branchType
    const endpoint = branchType
      ? `/v2/${campaignType}/branches?campaign=${slug}&branch_type=${branchType}`
      : `/v2/${campaignType}/branches?campaign=${slug}`
    commit('setBranchV2')
    try {
      const response = await axios.get(endpoint)
      commit('setBranchV2Success', response.data.data)
    } catch {
      commit('setBranchV2Error')
    }
  },
  setBranchSelect({ commit }, payload) {
    commit('setSelectBranch', payload)
  },
  resetBranchSelect({ commit }) {
    commit('resetBranch')
  },
}

// mutations
const mutations = {
  setBranch(state) {
    state.isLoading = true
    state.isError = false
    // state.step =
  },
  setBranchType(state, payload) {
    state.branchType = payload
    // state.step =
  },
  setSelectBranch(state, payload) {
    state.branchSelect = payload
    state.branchCodeSelect = state?.data?.id ?? null
  },
  setBranchSuccess(state, payload) {
    state.isLoading = false
    state.data = payload
      .map(item => {
        let count = 0
        item.times.map(i => {
          if (i.active) count++
        })
        item.disabled = count === 0
        item.seq = item.disabled ? 1 : 0
        item.name = !item.disabled
          ? item.name
          : `${item.name} ( เต็มแล้ว )`
        if (item.name === 'จัดส่งตามที่อยู่') {
          // item.image = Home
        } else {
          // item.image = Image
        }

        return { ...item }
      })
      .sort((a, b) => {
        return a.seq - b.seq
      })
    state.isError = false
  },
  resetBranch(state) {
    state.branchSelect = ''
    state.branchCodeSelect = null
  },
  setBranchDeposit(state, payload) {
    state.isLoading = false
    state.dataBranchDeposit = payload
    state.isError = false
  },
  setBranchFullPayment(state, payload) {
    state.isLoading = false
    state.dataBranchFullPayment = payload
    state.isError = false
  },
  setBranchError(state) {
    state.isLoading = false
    state.isError = true
  },
  setBranchDepositError(state) {
    state.isLoading = false
  },
  setBranchFullPaymentError(state) {
    state.isLoading = false
  },
  setBranchV2(state) {
    state.branchV2 = null
    state.isLoading = true
    state.isError = false
  },
  setBranchV2Error(state) {
    state.branchV2 = null
    state.isLoading = false
    state.isError = true
  },
  setBranchV2Success(state, payload) {
    state.branchV2 = payload
    state.isLoading = false
    state.isError = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
