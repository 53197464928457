import Vue from 'vue'
import axios from '../config/api'
const campaignType = process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'

// const mock_response = {
//   data: {
//     models: [
//       {
//         name: 'iPhone 14',
//         model: [
//           {
//             size: '128GB',
//             data: [
//               {
//                 color: 'Blue',
//                 color_hex: '#94ABBD',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Purple',
//                 color_hex: '#E6DBEB',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Midnight',
//                 color_hex: '#141B21',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Starlight',
//                 color_hex: '#F7F3F0',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: '(PRODUCT)RED',
//                 color_hex: '#E50925',
//                 'AT 3 SEP': false,
//               },
//             ],
//           },
//           {
//             size: '256GB',
//             data: [
//               {
//                 color: 'Midnight',
//                 color_hex: '#141B21',
//                 'AT 3 SEP': false,
//               },
//             ],
//           },
//           {
//             size: '512GB',
//             data: [
//               {
//                 color: 'Blue',
//                 color_hex: '#94ABBD',
//                 'AT 3 SEP': false,
//               },
//             ],
//           },
//         ],
//         model_name: {
//           'AT 3 SEP': 'iPhone 14',
//         },
//         campaign_id: {
//           'AT 3 SEP': 34,
//         },
//         campaign_name: {
//           'AT 3 SEP': 'AT 3 SEP',
//         },
//         campaigns: {
//           'AT 3 SEP': false,
//         },
//       },
//       {
//         name: 'iPhone 14 Plus',
//         model: [
//           {
//             size: '256GB',
//             data: [
//               {
//                 color: 'Starlight',
//                 color_hex: '#F7F3F0',
//                 'AT 3 SEP': true,
//               },
//               {
//                 color: 'Purple',
//                 color_hex: '#E6DBEB',
//                 'AT 3 SEP': true,
//               },
//               {
//                 color: '(PRODUCT)RED',
//                 color_hex: '#E50925',
//                 'AT 3 SEP': true,
//               },
//               {
//                 color: 'Blue',
//                 color_hex: '#94ABBD',
//                 'AT 3 SEP': true,
//               },
//             ],
//           },
//           {
//             size: '512GB',
//             data: [
//               {
//                 color: 'Midnight',
//                 color_hex: '#141B21',
//                 'AT 3 SEP': true,
//               },
//             ],
//           },
//         ],
//         model_name: {
//           'AT 3 SEP': 'iPhone 14 Plus',
//         },
//         campaign_id: {
//           'AT 3 SEP': 34,
//         },
//         campaign_name: {
//           'AT 3 SEP': 'AT 3 SEP',
//         },
//         campaigns: {
//           'AT 3 SEP': true,
//         },
//       },
//       {
//         name: 'iPhone 14 Pro',
//         model: [
//           {
//             size: '128GB',
//             data: [
//               {
//                 color: 'Deep Purple',
//                 color_hex: '#4F2964FF',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Gold',
//                 color_hex: '#F1E3C9',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Silver',
//                 color_hex: '#F6F6F6',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Space Black',
//                 color_hex: '#0C0B0B',
//                 'AT 3 SEP': true,
//               },
//             ],
//           },
//           {
//             size: '256GB',
//             data: [
//               {
//                 color: 'Space Black',
//                 color_hex: '#0C0B0B',
//                 'AT 3 SEP': true,
//               },
//               {
//                 color: 'Silver',
//                 color_hex: '#F6F6F6',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Gold',
//                 color_hex: '#F1E3C9',
//                 'AT 3 SEP': true,
//               },
//               {
//                 color: 'Deep Purple',
//                 color_hex: '#4F2964FF',
//                 'AT 3 SEP': false,
//               },
//             ],
//           },
//           {
//             size: '512GB',
//             data: [
//               {
//                 color: 'Space Black',
//                 color_hex: '#0C0B0B',
//                 'AT 3 SEP': true,
//               },
//             ],
//           },
//           {
//             size: '1TB',
//             data: [
//               {
//                 color: 'Silver',
//                 color_hex: '#F6F6F6',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Gold',
//                 color_hex: '#F1E3C9',
//                 'AT 3 SEP': true,
//               },
//               {
//                 color: 'Deep Purple',
//                 color_hex: '#4F2964FF',
//                 'AT 3 SEP': true,
//               },
//             ],
//           },
//         ],
//         model_name: {
//           'AT 3 SEP': 'iPhone 14 Pro',
//         },
//         campaign_id: {
//           'AT 3 SEP': 34,
//         },
//         campaign_name: {
//           'AT 3 SEP': 'AT 3 SEP',
//         },
//         campaigns: {
//           'AT 3 SEP': true,
//         },
//       },
//       {
//         name: 'iPhone 14 Pro Max',
//         model: [
//           {
//             size: '256GB',
//             data: [
//               {
//                 color: 'Space Black',
//                 color_hex: '#0C0B0B',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Silver',
//                 color_hex: '#F6F6F6',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Gold',
//                 color_hex: '#F1E3C9',
//                 'AT 3 SEP': false,
//               },
//             ],
//           },
//           {
//             size: '1TB',
//             data: [
//               {
//                 color: 'Gold',
//                 color_hex: '#F1E3C9',
//                 'AT 3 SEP': false,
//               },
//               {
//                 color: 'Deep Purple',
//                 color_hex: '#4F2964FF',
//                 'AT 3 SEP': false,
//               },
//             ],
//           },
//         ],
//         model_name: {
//           'AT 3 SEP': 'iPhone 14 Pro Max',
//         },
//         campaign_id: {
//           'AT 3 SEP': 34,
//         },
//         campaign_name: {
//           'AT 3 SEP': 'AT 3 SEP',
//         },
//         campaigns: {
//           'AT 3 SEP': false,
//         },
//       },
//     ],
//     campaigns: [
//       {
//         name: 'AT 3 SEP',
//         slug: 'xxx',
//       },
//     ],
//   },
//   success: true,
//   count: 2,
//   message: 'ทำรายการสำเร็จ',
//   status: 200,
// }
const state = () => ({
  data: null,
  dataV2: null,
  payload: null,
  hasData: false,
  isError: false,
  errorMessage: null,
  isLoading: false,
  isVerified: false,
})

// getters
const getters = {
  isLoading: state => state.isLoading,
}

// actions
const actions = {
  async getStockLive({ commit }, { slug, refetch }) {
    try {
      if (refetch) {
        commit('setStockData')
      }
      const response = await axios.get(
        `/products/live/stock/${campaignType}/${slug}`,
      )
      const { campaigns } = response.data.data
      const stock = response.data.data
      for (const model of stock.models) {
        for (const m of model.model) {
          for (const campaign of campaigns) {
            let status = false
            for (const color of m.data) {
              color.active = color?.[campaign.name]
              if (color?.[campaign.name]) {
                status = true
              }
            }
            m[campaign.name] = status
          }
        }
      }

      commit('setStockDataSuccess', stock)
    } catch {
      commit('setStockDataError')
    }
  },

  async getStockLiveV2({ commit }, { slug, refetch }) {
    if (!slug) {
      return
    }

    try {
      if (refetch) {
        commit('setStockDataV2')
      }
      const response = await axios.get(
        `/v2/products/live/stock/${campaignType}/${slug}`,
      )
      // const { campaigns } = response.data.data
      // const stock = response.data.data
      // for (const model of stock.models) {
      //   for (const m of model.model) {
      //     for (const campaign of campaigns) {
      //       let status = false
      //       for (const color of m.data) {
      //         color.active = color?.[campaign.name]
      //         if (color?.[campaign.name]) {
      //           status = true
      //         }
      //       }
      //       m[campaign.name] = status
      //     }
      //   }
      // }

      commit('setStockDataV2Success', response.data.data)
    } catch {
      commit('setStockDataV2Error')
    }
  },

  async staffVerify({ commit }, payload) {
    commit('staffVerifyRequest')
    await axios
      .post('/staff/verify', payload)
      .then(response => {
        commit('staffVerifyRequestSuccess', response)
        Vue.prototype.$cookies.set('sc', payload.staff_code)
        Vue.prototype.$cookies.set('bc', payload.branch_code)
      })
      .catch(error => {
        commit(
          'staffVerifyRequestFailure',
          error.response?.data?.message,
        )
        Vue.prototype.$cookies.remove('sc')
        Vue.prototype.$cookies.remove('bc')
      })
  },

  async generateProductLink(context, payload) {
    return await axios
      .post('staff/generate-product-link', payload)
      .then(response => {
        return response.data.data?.url ?? ''
      })
  },

  resetVerify({ commit }) {
    commit('staffVerifyRequest')
  },
}

// mutations
const mutations = {
  setHasData(state, value) {
    state.hasData = value
  },
  setStockData(state) {
    state.isLoading = true
    state.data = null
    state.hasData = false
    state.isError = false
  },
  setStockDataSuccess(state, payload) {
    state.hasData = payload?.campaigns?.length > 0
    state.isLoading = false
    state.data = payload
    state.isError = false
  },
  setStockDataError(state) {
    state.isLoading = false
    state.isError = true
    state.hasData = false
  },
  setStockDataV2(state) {
    state.isLoading = !state?.dataV2
    state.dataV2 = state?.dataV2
    state.hasData = state?.dataV2 || false
    state.isError = false
  },
  setStockDataV2Success(state, payload) {
    state.hasData = true
    state.isLoading = false
    state.dataV2 = payload
    state.isError = false
  },
  setStockDataV2Error(state) {
    state.isLoading = false
    state.isError = true
    state.hasData = false
  },
  staffVerifyRequest(state) {
    state.isVerified = false
    state.errorMessage = null
  },
  staffVerifyRequestSuccess(state) {
    state.isVerified = true
    state.errorMessage = null
  },
  staffVerifyRequestFailure(state, payload) {
    state.isVerified = false
    state.errorMessage = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
