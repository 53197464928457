import axios from '../config/api'

const state = () => ({
  data: [],
  paymentId: null,
  installmentPlan: null,
  payload: null,
  isError: false,
  isLoading: false,
  edc: {
    code: [],
    choices: [],
    promotions: [],
    order_types: [],
    pay_at_store_choices: [],
    card_types: [],
    isSuccess: false,
    isError: false,
    isLoading: false,
  },
  voucher: {
    data: {},
    code: '',
    isSuccess: false,
    isError: false,
    errorMessage: '',
    isLoading: false,
  },
})

// getters
const getters = {
  getPaymentTypeById: state => id => {
    return state.data?.find(i => i?.id === id) ?? null
  },

  getCurrentPaymentType: state => {
    return state.data?.find(i => i?.id === state.paymentId) ?? null
  },

  getCardTypesByPayAtStoreChoiceId: state => id => {
    const payAtStoreChoiceSelected = state.edc.pay_at_store_choices.find(
      i => i.id === id,
    )

    if (!payAtStoreChoiceSelected) {
      return []
    }

    return state.edc.card_types.filter(
      i => i.payment_type === payAtStoreChoiceSelected.payment_type,
    )
  },

  getPaymentTypeByPayAtStoreChoiceId: state => id => {
    return (
      state.edc.pay_at_store_choices.find(i => i.id === id) ?? null
    )
  },
}

// actions
const actions = {
  async getPaymentType({ commit }, id) {
    //  if (state.data.length === 0) {
    try {
      const response = await axios.get(
        `/v2/payment-types/?campaign_id=${id}`,
      )
      commit('setPaymentTypeSuccess', response.data.data)
    } catch {
      commit('setPaymentTypeError')
    }
    // }
  },

  async getEdcByBranch({ commit }, code) {
    commit('setEdcBranchRequest')

    try {
      const response = await axios.get(
        `/branche-edc?branch_code=${code}`,
      )

      commit('setEdcBranchSuccess', response.data.data)
    } catch {
      commit('setEdcBranchError')
    }
  },

  async verifyVoucher({ commit }, payload) {
    commit('setVerifyVoucherRequest', payload)
    try {
      const response = await axios.post(
        '/orders/verify-voucher',
        payload,
      )
      commit('setVerifyVoucherSuccess', response.data.data)
    } catch (error) {
      commit('setVerifyVoucherError', error.response.data.message)
    }
  },
}

// mutations
const mutations = {
  setPaymentId(state, payload) {
    state.paymentId = payload
  },
  setPaymentInstallmentPlan(state, payload) {
    state.installmentPlan = payload
  },
  setPaymentType(state) {
    state.isLoading = true
    state.isError = false
  },
  setPaymentTypeSuccess(state, payload) {
    state.isLoading = false
    state.data = payload
    state.isError = false
  },
  setPaymentTypeError(state) {
    state.isLoading = false
    state.isError = true
  },
  setEdcBranchRequest(state) {
    state.edc.isLoading = true
    state.edc.isError = false
  },
  setEdcBranchSuccess(state, payload) {
    state.edc.isLoading = false
    state.edc.code = payload.edc_code
    state.edc.order_types = payload.order_types
    state.edc.pay_at_store_choices = payload.pay_at_store_choices
    state.edc.card_types = payload.card_types
    state.edc.isError = false
  },
  setEdcBranchError(state) {
    state.edc.isLoading = false
    state.edc.isError = true
  },
  setVerifyVoucherRequest(state, payload) {
    state.voucher.code = payload.voucher_code ?? null
    state.voucher.isLoading = true
    state.voucher.isError = false
    state.voucher.errorMessage = ''
  },
  setVerifyVoucherSuccess(state, payload) {
    state.voucher.isLoading = false
    state.voucher.data = payload
    state.voucher.isSuccess = true
    state.voucher.isError = false
    state.voucher.errorMessage = ''
  },
  setVerifyVoucherError(state, payload) {
    state.voucher.isLoading = false
    state.voucher.isError = true
    state.voucher.isSuccess = false
    state.voucher.errorMessage =
      payload ?? 'ไม่สามารถใช้ Voucher นี้ได้'
  },
  setVerifyVoucherReset(state) {
    state.voucher.data = {}
    state.voucher.code = ''
    state.voucher.isLoading = false
    state.voucher.isSuccess = false
    state.voucher.isError = false
    state.voucher.errorMessage = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
