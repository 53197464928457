import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

// Note: Turn off Sentry
const SENTRY_DSN = false
// process.env.VUE_APP_SENTRY_DSN || false

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    environment: process.env.VUE_APP_ENV,
  })
}
