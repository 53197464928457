<template>
  <v-app @click="showSummary">
    <Header />
    <router-view> </router-view>
    <Error />
    <Ribbon v-if="isDev" />
    <FooterFullStudio7
      v-if="
        [
          'ProductDetail',
          'CountDown',
          'Campaign',
          'Error',
          'NotFound',
        ].includes($route.name)
      "
      :style="{
        'margin-bottom': ['ProductDetail'].includes($route.name)
          ? elementHeight + 'px'
          : '0px',
      }"
    />
    <Footer
      v-if="
        ![
          'Cart',
          'Thank',
          'OrderDetail',
          'ProductDetail',
          'CountDown',
          'Campaign',
          'Error',
          'NotFound',
        ].includes($route.name)
      "
      :style="{
        'margin-bottom': ['ProductDetail'].includes($route.name)
          ? elementHeight + 'px'
          : '0px',
      }"
    />
  </v-app>
</template>

<script>
window.addEventListener(
  'pageshow',
  function(evt) {
    if (evt?.persisted) {
      setTimeout(function() {
        window.location.reload()
      }, 10)
    }
  },
  false,
)

import { mapState } from 'vuex'
import axios from '@/config/api'
import Header from '@/components/bnn/Header'
import Error from '@/components/Error'
import Ribbon from '@/components/Ribbon'
import Footer from '@/components/bnn/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Error,
    Ribbon,
    Footer,
    FooterFullStudio7: () =>
      import('@/components/studio7/FooterFullStudio7'),
  },

  data() {
    return {
      isDev: process.env.NODE_ENV === 'development',
      elementHeight: 110,
    }
  },
  computed: {
    ...mapState({
      campaign: state => state.campaign.current,
    }),
  },

  mounted() {
    if (event?.persisted) {
      window.location.reload()
    }

    document.addEventListener('mouseup', () => {
      this.showSummary()
    })

    // this.loadCampaign()

    this.$nextTick(() => {
      setTimeout(() => {
        this.updateElementHeight()
      }, 1000)
    })

    window.addEventListener('resize', this.updateElementHeight)
  },

  beforeDestroy() {
    window.removeEventListener('mouseup', this.showSummary)
    window.removeEventListener('resize', this.updateElementHeight)
  },

  // watch: {
  //   $route(to) {
  //     if (this.campaign && to.name === 'Load') {
  //       this.$router.push({ name: 'Home' })
  //     }

  //     if (to.name === 'Load' || to.name === 'NoCampaign') {
  //       if (to.name === 'Load') {
  //         this.loadCampaign()
  //       }
  //     }
  //   },
  // },

  methods: {
    showSummary() {
      this.$store.dispatch('cart/showSummary', true)
      this.$forceUpdate()
    },

    async loadCampaign() {
      if (this.campaign) {
        return
      }

      const result = await axios.get('/campaigns')
      const val = result?.data?.data || null
      if (val?.id) {
        this.$store.commit('campaign/update', val)
      }
      this.$store.commit('campaign/updateLoading', false)

      if (
        this.campaign &&
        this.$route.name !== 'Home' &&
        this.$route.name !== 'Thank'
      ) {
        this.$router.push({ name: 'Home' })
      } else if (
        !this.campaign &&
        this.$route.name !== 'NoCampaign'
      ) {
        this.$router.push({ name: 'NoCampaign' })
      }
    },

    updateElementHeight() {
      setTimeout(() => {
        const element = document.querySelector(
          '[data-pptr="PDPStickyCTA"]',
        )

        if (element) {
          this.elementHeight = element.offsetHeight
        }
      }, 500)
    },
  },
}
</script>

<style lang="stylus">

// font
@font-face
  font-family: 'Sukhumvit Set'
  src: url('assets/fonts/sukhumvit-set-text.woff2') format('woff2')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'SukhumvitSet'
  src: url('assets/fonts/sukhumvit-set-text.woff2') format('woff2')
  font-weight: normal
  font-style: normal


@font-face
  font-family: 'sukhumvit'
  src: url('assets/fonts/sukhumvit.eot')
  src: url('assets/fonts/sukhumvit.eot?#iefix') format('embedded-opentype')
  src: url('assets/fonts/sukhumvit.woff2') format('woff2')
  src: url('assets/fonts/sukhumvit.woff') format('woff')
  src: url('assets/fonts/sukhumvit.ttf') format('truetype')
  font-weight: normal
  font-style: normal

// SFPro
// Text
@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/sf-pro-text_regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/SFProThai-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/sf-pro-text_medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/SFProTH.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/sf-pro-text_semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/SFProThai-Semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/sf-pro-text_bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("assets/fonts/SFProThai-Semibold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

// Display
@font-face {
	font-family: "SF Pro Display";
	src: url("assets/fonts/sf-pro-display_semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Display";
	src: url("assets/fonts/SFProThai-Semibold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
	unicode-range: U+0E00-0E7F; // For thai word
}

// [END] SFPro

body
  color: #434242
  font-family: 'sukhumvit', 'Sukhumvit Set', 'Prompt', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif !important

.container{
    max-width: 100dvw
    padding 0 12px

    @media screen and (min-width: 768px){
        max-width: 1220px
    }

    @media (min-width: 1024){
        max-width: 1220px !important
    }
}

.header-margin
  height: 100px

.footer
  height: 60px
  background-color: $color-gray-400
  z-index 7
</style>
