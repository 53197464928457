import axios from '../config/api'

const state = () => ({
  data: [],
  warrantyPrice: 0,
  paymentData: null,
  paymentSuccess: false,
  payload: null,
  paymentDataError: null,
  isPaymentError: false,
  isPaymentRedirect: false,
  isError: false,
  isLoading: false,
})

// getters
const getters = {
  getStep: state => {
    return state.step
  },
}

// actions
const actions = {
  setForm({ commit }, payload) {
    commit('setForm')
    commit('setFormSuccess', payload)
  },
  submitForm({ state, commit }) {
    const { data } = state
    let paymentType = 0
    const cardBrand = data?.cardBrand || '2C2P'
    if (!data?.delivery) {
      if (data?.paymentChannel === 'visa') {
        paymentType = 1
      } else if (data?.paymentChannel === 'krungsri') {
        paymentType = 2
      } else if (data?.paymentChannel === 'qrCode') {
        paymentType = 3
      } else {
        paymentType = 1
      }
    } else {
      paymentType = 4
      //   if (data?.paymentChannelType === 'normal') {
      //     if (data?.paymentChannel === 'visa') {
      //       paymentType = 4
      //     }
      //     if (data?.paymentChannel === 'krungsri') {
      //       paymentType = 5
      //     }
      //     if (data?.paymentChannel === 'qrCode') {
      //       paymentType = 6
      //     }
      //   } else {
      //     if (data?.paymentChannel === 'kbank') {
      //       paymentType = 7
      //     }
      //     if (data?.paymentChannel === 'krungsri') {
      //       paymentType = 8
      //     }
      //     if (data?.paymentChannel === 'installment2c2p') {
      //       paymentType = 9
      //     }
      //   }
    }
    commit('setDataFromClearError')
    let isDelivery = false
    if (paymentType >= 4 && paymentType <= 6) {
      isDelivery = true
    } else {
      if (data?.branch?.name === 'จัดส่งตามที่อยู่') {
        isDelivery = true
      } else {
        isDelivery = false
      }
    }
    const payload = {
      product_id: data?.productId,
      branch_time_id: data?.branchTimeId,
      // payment_type: 'CREDIT',
      // payment_code: 'C003',
      first_name: data?.firstName,
      last_name: data?.lastName,
      phone: data?.phoneNumber,
      email: data?.email,
      address: data?.addressBill,
      sub_district: data?.subDistrict,
      district: data?.district,
      province: data?.province,
      country: data?.country,
      postcode: data?.postalCode,
      remark: data?.forWork,
      bundle_products: data?.bundleProducts,
      payment_type_id: paymentType,
      is_delivery: isDelivery,
    }
    commit('setPaymentDataFromClearError')
    axios
      .post(`/orders`, payload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(response => {
        if (cardBrand === 'BAY') {
          const inputPayment = {
            order_id: response.data.data.uuid,
            term: data?.term,
            interest: 0.0,
            card_type: data?.cardType,
            html: '1',
          }
          axios
            .post('/payments/krungsri', inputPayment)
            .then(res => {
              let data = res.data
              // console.log(res)
              let find = '<form'
              let re = new RegExp(find, 'g')
              data = data.replace(
                re,
                `<form ref="form" class="flex flex-col w-full justify-center flex-wrap items-center" `,
              )
              find = 'type="text"'
              re = new RegExp(find, 'g')
              data = data.replace(
                re,
                `type="text" class="shadow appearance-none border rounded  w-full  py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" `,
              )
              find = 'type="submit"'
              re = new RegExp(find, 'g')
              data = data.replace(
                re,
                `type="submit" class="w-4/12 bg-green-500 hover:bg-green-600 py-2 px-3 rounded-md text-gray-100 border-2 border-green-600 cursor-pointer" `,
              )
              commit('setPaymentDataFrom', data)
            })
            .catch(err => {
              commit('setPaymentDataFromError', err)
            })
        } else {
          const inputPayment = {
            order_id: response.data.data.uuid,
            // amount: response.data.data.total_price,
            // description: 'test',
            html: '1',
          }
          axios
            .post('/payments/2c2p', inputPayment)
            .then(res => {
              let data = res.data
              // console.log(res)
              let find = '<form'
              let re = new RegExp(find, 'g')
              data = data.replace(
                re,
                `<form ref="form" class="flex flex-col w-full justify-center flex-wrap items-center" `,
              )

              find = 'type="text"'
              re = new RegExp(find, 'g')
              data = data.replace(
                re,
                `type="text" class="shadow appearance-none border rounded  w-full  py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline" `,
              )

              find = 'type="submit"'
              re = new RegExp(find, 'g')
              data = data.replace(
                re,
                `type="submit" class="w-4/12 bg-green-500 hover:bg-green-600 py-2 px-3 rounded-md text-gray-100 border-2 border-green-600 cursor-pointer" `,
              )
              commit('setPaymentDataFrom', data)
            })
            .catch(err => {
              commit('setPaymentDataFromError', err)
            })
        }

        //  paymentData

        // if (response.data.status) {
        //   const payload = {
        //     invoiceNo: response.data.data.invoice_no,
        //     bookingStatus: response.data.status,
        //   }
        //   commit('setFormSuccess', payload)
        //   commit('paymentSuccess')
        // } else {
        //   const payload = {
        //     invoiceNo: undefined,
        //     bookingStatus: response.data.status,
        //   }
        //   commit('setFormSuccess', payload)
        // }
      })
      .catch(() => {
        // commit('setBranchError')
        commit('setFormError')
      })
  },
}

// mutations
const mutations = {
  setForm(state) {
    state.isLoading = true
    state.isError = false
    // state.step =
  },
  setDataFromClearError(state) {
    state.isLoading = false
    state.isError = false
  },
  setPaymentDataFrom(state, payload) {
    state.isLoading = false
    state.isPaymentError = false
    state.isPaymentRedirect = true
    state.paymentData = payload
    state.paymentDataError = null
  },
  setPaymentDataFromClearError(state) {
    state.isLoading = true
    state.isPaymentError = false
    state.isPaymentRedirect = false
    state.paymentData = null
    state.paymentDataError = null
  },
  setPaymentDataFromError(state, payload) {
    state.isLoading = false
    state.isPaymentError = true
    state.isPaymentRedirect = false
    state.paymentData = null
    state.paymentDataError = payload.response.data
  },
  paymentSuccess(state) {
    state.isLoading = false
    state.isError = false
    state.paymentSuccess = true
    // state.step =
  },
  setFormSuccess(state, payload) {
    state.isLoading = false
    state.isError = false
    localStorage.formData = JSON.stringify({
      ...state.data,
      ...payload,
    })
    state.data = JSON.parse(localStorage.formData)
    // state.step =
  },
  setFormError(state) {
    state.isLoading = false
    state.isError = true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
