<template>
  <div @click="onClick">
    <button class="font-semibold" :id="color" :class="buttonClass">
      <div class="d-flex align-center justify-center">
        <div class="mr-2">
          <v-progress-circular
            v-if="isLoading"
            :width="3"
            :size="20"
            color="white"
            indeterminate
          ></v-progress-circular>
        </div>
        <span>{{ title }}</span>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CustomButton',

  props: {
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      if (!this.isLoading) this.$emit('click')
    },
  },

  computed: {
    buttonClass() {
      if (this.isLoading) {
        return `btn-${this.color} isLoading`
      } else {
        return `btn-${this.color}`
      }
    },
  },
}
</script>

<style lang="stylus">
@import '../assets/css/style.styl'

.btn-green
  background-color: $color-green
  padding: 0.5rem
  color: white
  width: 100%
  border-radius: 4px

.btn-white
  background-color: white
  border 1px solid $color-gray-border
  padding: 0.5rem
  color: $color-dark-gray
  width: 100%
  border-radius: 4px

.btn-gray
  background-color: $color-gray-100
  color: $color-black-100
  padding: 0.5rem
  width: 100%
  border-radius: 4px

.isLoading
  cursor not-allowed !important
  opacity 0.6
</style>
