const state = () => ({
  selects: {},
  delivery: {},
})

// getters
const getters = {
  countBySKU: state => item => {
    const sku = item.sku
    return state.selects[sku] || 0
  },
}

// actions
const actions = {
  reset({ commit }) {
    commit('clear')
  },

  toggle({ commit }, item) {
    commit('toggle', item)
  },

  add({ commit }, item) {
    commit('add', item)
  },

  selectDelivery({ commit }, item) {
    commit('setDelivery', item)
  },

  remove({ commit }, item) {
    commit('remove', item)
  },
}

// mutations
const mutations = {
  clear(state) {
    state.selects = {}
  },

  toggle(state, item) {
    const sku = item?.sku
    if (item?.active) {
      state.selects[sku]
        ? (state.selects[sku] = 0)
        : (state.selects[sku] = 1)
    }

    // force update
    state.selects = { ...state.selects }
  },

  add(state, item) {
    const sku = item?.sku
    const limit = item.limit

    if (!state.selects[sku]) {
      state.selects[sku] = 0
    }

    if (state.selects[sku] < limit) {
      state.selects[sku] += 1
    }

    // force update
    state.selects = { ...state.selects }
  },

  setDelivery(state, item) {
    state.delivery = {}
    const sku = item?.sku
    const limit = item?.limit

    if (!state.delivery?.[sku]) {
      state.delivery[sku] = 0
    }

    if (state.delivery?.[sku] < limit) {
      state.delivery[sku] += 1
    }

    // force update
    state.delivery = { ...state.delivery }
  },

  resetDelivery(state) {
    state.delivery = {}
  },

  remove(state, item) {
    const sku = item?.sku

    if (!state.selects[sku]) {
      state.selects[sku] = 0
    }

    if (state.selects[sku] >= 1) {
      state.selects[sku] -= 1
    }

    // force update
    state.selects = { ...state.selects }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
